/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { Flex, Box } from '@rebass/grid/emotion';
import { useStaticQuery, graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../../components/layout';
import SEO from '../../components/seo';
import Container from '../../components/Container';
import Hero from '../../components/Hero';
import { H1, H2 } from '../../components/StyledHeaders';
import StandardSection from '../../components/StandardSection';
import css from '@emotion/css';
import Button from '../../components/Button';
import HowWeWork from '../../components/HowWeWork';
import NonStretchedImage from '../../utils/NonStretchedImages';

const HiddenBox = styled(Box)`
  display: block;
  ${(props) => props.smDown && css`@media (max-width: ${props.theme.breakpoints[0]}) {display: none;}`}
`;

const WebDevelopment = (props) => {
	const data = useStaticQuery(graphql`
		query {
			placeholderImage: file(relativePath: { eq: "alexa.jpg" }) {
				childImageSharp {
					fluid(maxWidth: 2560, quality: 100) {
						...GatsbyImageSharpFluid
						presentationWidth
					}
				}
			}
			sideImage: file(relativePath: { eq: "alexa-echo.png" }) {
				childImageSharp {
					fluid(maxWidth: 512, quality: 100) {
						...GatsbyImageSharpFluid
					}
				}
			}
		}
	`);

	return (
		<Layout>
			<SEO title="Alexa Skills Development" />

			<Hero height={[ '40vh', '50vh' ]}>
				<Container>
					<div style={{ maxWidth: 720 }}>
						<H1>Alexa Skill Development</H1>
					</div>
				</Container>
				<NonStretchedImage {...data.placeholderImage.childImageSharp} />
			</Hero>

			<StandardSection>
				<Container>
					<Flex flexWrap="wrap" mx={[ -2, -3 ]} py={'60px'}>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>Voice activated applications with artificial intelligence</H2>
							<p style={{ fontSize: 16, textAlign: 'justify' }}>
								Attract more customers and better serve those you already have with the use of new
								technologies. Implement voice applications to interact with your users more effectively,
								naturally and smoothly. Offer services, support and help, get to know your customers
								better, complete sales, issue reminders and alerts in a timely and friendly manner,
								promote, build loyalty, etc.
							</p>
						</Box>
						<HiddenBox width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto" />

						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<Img
								fluid={data.sideImage.childImageSharp.fluid}
								style={{ width: 320, margin: '0 auto' }}
							/>
						</Box>
						<Box width={[ 1, 1 / 2 ]} px={[ 1, 3 ]} my={[ 1, 2 ]} flex="1 1 auto">
							<H2>Advantages</H2>
							<ol style={{ fontSize: 16, textAlign: 'justify' }}>
								<li>Full automated experience with the use of smart speakers</li>
								<li>Let your company be more inclusive with users with different abilities</li>
								<li>You will save time and money</li>
							</ol>
						</Box>

						<Box width={[ 1 ]} px={[ 1, 3 ]} style={{ textAlign: 'center' }}>
							<Button primary="true" as={Link} to={`${props.uri}#contact-us`}>
								Request more info
							</Button>
						</Box>
					</Flex>
				</Container>

				<HowWeWork uri={props.uri} />
			</StandardSection>
		</Layout>
	);
};

export default WebDevelopment;
